import { t } from 'i18next'
import { Button, Form, FormInstance, InputNumber, Popconfirm, Select } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import { CusTable } from '@/components/CusTable'
import { TFormValues } from '../interface'

export const amountDeclaresRender = (form: FormInstance<TFormValues>, allValues: TFormValues, disabledBtn: boolean) => {
  const options = [
    { value: 1, label: t('LocalProductList.mei-guo') },
    { value: 2, label: t('LocalProductList.jia-na-da') },
    { value: 3, label: t('LocalProductList.ri-ben') },
    { value: 4, label: t('LocalProductList.ou-meng') },
    { value: 5, label: t('LocalProductList.qi-ta') },
  ]
  const rowIdPrefix = 'LocalProductList_Dedail_amountDeclares_'
  const getRequiredTitle = (val: string) => (
    <>
      {!!allValues.amountDeclares.length && <span style={{ marginRight: 4, color: 'red' }}>*</span>}
      {val}
    </>
  )
  return (
    <Form.List name="amountDeclares">
      {(fields, { add, remove }) => (
        <CusTable
          locale={{ emptyText: <div style={{ padding: 6 }}>{t('2-common.zan-wu-shu-ju')}</div> }}
          rowKey="name"
          dataSource={fields}
          onRow={(_d, i) => ({ id: `${rowIdPrefix}${i}` })}
          columns={[
            {
              title: getRequiredTitle(t('LocalProductList.shen-bao-guo-jia')),
              width: '27%',
              render: (_v, _r, index) => (
                <Form.Item noStyle name={[index, 'declareCountry']} rules={[{ required: true }]}>
                  <Select
                    style={{ width: 220 }}
                    placeholder={t('2-common.qing-xuan-ze')}
                    options={options.map(opt => ({
                      ...opt,
                      disabled: allValues.amountDeclares.some((d, i) => i !== index && d.declareCountry === opt.value),
                    }))}
                  />
                </Form.Item>
              ),
            },
            {
              title: getRequiredTitle(t('LocalProductList.shen-bao-fang-shi')),
              width: '27%',
              render: (_v, _r, index) => (
                <Form.Item noStyle name={[index, 'declareMethod']} rules={[{ required: true }]}>
                  <Select
                    style={{ width: 220 }}
                    placeholder={t('2-common.qing-xuan-ze')}
                    options={[
                      { value: 1, label: t('LocalProductList.an-gu-ding-jin-e') },
                      { value: 2, label: t('LocalProductList.an-ding-dan-jin-e') },
                    ]}
                    onChange={() => form.setFieldValue(['amountDeclares', index, 'declareValue'], undefined)}
                  />
                </Form.Item>
              ),
            },
            {
              title: getRequiredTitle(t('LocalProductList.shen-bao-jine-bi-li')),
              width: '27%',
              render: (_v, _r, index) => {
                const { declareMethod } = allValues.amountDeclares[index] || {}
                const isPercentage = declareMethod === 2
                return (
                  <Form.Item noStyle name={[index, 'declareValue']} rules={[{ required: true }]}>
                    <InputNumber
                      style={{ width: 220 }}
                      placeholder={t('2-common.qing-shu-ru')}
                      addonAfter={<div style={{ width: 28 }}>{isPercentage ? '%' : 'USD'}</div>}
                      min={isPercentage ? 1 : 0.01}
                      max={isPercentage ? 99 : undefined}
                      precision={isPercentage ? 0 : 2}
                      stringMode
                    />
                  </Form.Item>
                )
              },
            },
            {
              title: (
                <Button
                  size="small"
                  type="primary"
                  disabled={disabledBtn || fields.length >= options.length}
                  ghost
                  icon={<PlusOutlined />}
                  onClick={() => {
                    add({ declareCountry: undefined, declareMethod: 1, declareValue: undefined })
                    const lastIndex = allValues.amountDeclares.length
                    setTimeout(() => {
                      const rowId = `${rowIdPrefix}${lastIndex}`
                      document.getElementById(rowId)?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
                    }, 100)
                  }}
                >
                  {t('2-common.tian-jia')}
                </Button>
              ),
              width: '19%',
              align: 'center',
              render: (_v, _r, index) => (
                <Popconfirm title={t('2-common.que-ren-yao-shan-chu-ma')} onConfirm={() => remove(index)}>
                  <Button type="link" icon={<DeleteOutlined />} danger disabled={disabledBtn} />
                </Popconfirm>
              ),
            },
          ]}
        />
      )}
    </Form.List>
  )
}
