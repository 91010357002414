import { useEffect, useId, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import {
  Anchor,
  Button,
  Col,
  Divider,
  Form,
  FormProps,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  theme,
} from 'antd'
import { PlusOutlined, SaveOutlined } from '@ant-design/icons'

import { FormItemUpload } from '@/components/FormItemUpload'
import { Loading } from '@/components/Loading'
import { useFormAllValues } from '@/hooks/hooks'
import { fileValidate, formAutoTrimOnBlur, toThousands } from '@/utils/utils'
import { SupplierSelect } from '../../components/SupplierSelect'
import { apiAddOrEdit, apiGetDetail, apiRecheck } from '../apis'
import { IDetail, IRecord, TFormValues, TRecheckFormValues } from '../interface'
import {
  getUnitText,
  PACKING_MATERIAL_TYPE,
  SIZE_UNIT,
  usePACKING_MATERIAL_TYPE_NAME_MAP,
  useSIZE_UNIT_NAME_MAP,
} from '../utils'
import { SizeInput } from './SizeInput'
import { useRecheckForm } from './useRecheckForm'
import { createInitialFormValues, textInputFields } from './utils'
import styles from './styles.module.less'

/** 包材详情 */
export const DedailWrap = ({
  isEmployee,
  isEmployeeStore,
  record,
  onReturn,
  onSuccess,
}: {
  /** 员工侧 */
  isEmployee: boolean
  /** 员工侧-仓库 */
  isEmployeeStore?: boolean
  /** 新增类型(仅新增，必传) --- 包材的不需要传 */
  addProductType?: number | null
  /** 有值则视为编辑 */
  record: IRecord | null
  /** 返回 */
  onReturn: () => void
  /** 新增或编辑成功回调 */
  onSuccess?: () => void
}) => {
  const { t } = useTranslation()
  const { token } = theme.useToken()
  const isEdit = !!record
  const skuId = record?.skuId
  const idPrefix = useId()
  const scrollRef = useRef<HTMLDivElement>(null)

  const [fetching, setFetching] = useState(isEdit && !!skuId)
  const [detail, setDetail] = useState<IDetail | null>(null)
  const [form] = Form.useForm<TFormValues>()
  const initialFormValues = useMemo(() => createInitialFormValues(2, null), [])
  const allValues = useFormAllValues(form, initialFormValues)

  const { recheckForm, initialRecheckFormValues, recheckAllValues } = useRecheckForm()

  const formProps: FormProps<TFormValues> = {
    form,
    scrollToFirstError: { block: 'center', behavior: 'smooth' },
    layout: 'vertical',
    disabled: isEmployeeStore,
    preserve: true,
    initialValues: initialFormValues,
    onBlur: e => formAutoTrimOnBlur({ event: e, form, includeField: textInputFields }),
  }

  const col1Props = { xxl: 6, sm: 8, xs: 8 }
  const colNProps = { xxl: 24, sm: 24, xs: 24 }
  const sizeUnitNameMap = useSIZE_UNIT_NAME_MAP()
  const packingMaterialTypeNameMap = usePACKING_MATERIAL_TYPE_NAME_MAP()
  const imgUrls = [allValues.imgUrl, allValues.img2, allValues.img3].filter(Boolean) as string[]

  useEffect(() => {
    if (!isEdit || !skuId) return
    setFetching(true)
    apiGetDetail(skuId)
      .then(res => {
        const { localProductRecheckVo, ...rest } = res
        const values = createInitialFormValues(2, rest)
        setDetail(res)
        form.setFieldsValue(values)
        recheckForm.setFieldsValue(
          _.mapValues(
            {
              ...initialRecheckFormValues,
              ...localProductRecheckVo,
              merchantUserId: rest.merchantUserId,
            },
            (v, k) => {
              if (k === 'logisticsAttributeRecheck') return v ?? []
              return v ?? undefined
            },
          ) as TRecheckFormValues,
        )
      })
      .finally(() => setFetching(false))
  }, [form, initialRecheckFormValues, isEdit, recheckForm, skuId])

  // 基础信息
  const formRender0 = () => {
    return (
      <Form {...formProps}>
        <Row gutter={12}>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.bao-cai-id')}>
              <span style={{ marginLeft: 3 }}>{detail?.skuId || '--'}</span>
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label="SKU" name="sku" rules={[{ required: true }]}>
              <Input disabled={formProps.disabled || isEdit} allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item
              label={t('LocalProductList.ying-wen-pin-ming')}
              name="productEnTitle"
              rules={[{ required: true }]}
            >
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.zhong-wen-pin-ming')} name="productCTitle">
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.gong-ying-shang')} name="sysSupplierIdList">
              <SupplierSelect
                style={{ maxWidth: 'auto' }}
                merchantUserId={isEmployee ? `${detail?.merchantUserId}` : undefined}
                multiple
                placeholder={t('2-common.qing-xuan-ze')}
              />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.upc-ma')} name="upc">
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.fnsku-ma')} name="fnsku">
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.qi-ta-bao-cai-tiao-ma')} name="otherCode">
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.xi-tong-sheng-cheng-bao-cai-tiao-ma')}>
              <span style={{ marginLeft: 3 }}>{detail?.sysBarCode || '--'}</span>
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.cai-gou-jia-ge')} name="purchasePrice">
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                precision={2}
                stringMode
                formatter={v => toThousands(v, { placeholder: '', precision: null })}
                addonAfter="USD"
                placeholder={t('2-common.qing-shu-ru')}
              />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item
              label={
                <>
                  {t('LocalProductList.bao-cai-lei-xing')}
                  <span style={{ color: token.colorTextDescription }}>
                    {t('LocalProductList.xuan-ding-que-ren-hou-bu-ke-zai-xiu-gai')}
                  </span>
                </>
              }
              required
            >
              <Form.Item
                label={t('LocalProductList.bao-cai-lei-xing')}
                noStyle
                name="packingMaterialType"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={t('2-common.qing-xuan-ze')}
                  allowClear
                  disabled={formProps.disabled || isEdit}
                  options={PACKING_MATERIAL_TYPE.map(v => ({
                    value: v,
                    label: packingMaterialTypeNameMap[v],
                  }))}
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t('LocalProductList.bao-cai-tu-pian')}>
              <FormItemUpload
                reqData={{ moduleType: 1 }}
                listType="picture-card"
                maxCount={3}
                accept={fileValidate.IMG_ACCEPT}
                beforeUpload={file => {
                  const msg = fileValidate(file, { accept: fileValidate.IMG_ACCEPT })
                  if (msg) {
                    message.error(msg)
                    return FormItemUpload.LIST_IGNORE
                  }
                }}
                value={imgUrls}
                onChange={urls => {
                  form.setFieldValue('imgUrl', urls[0])
                  form.setFieldValue('img2', urls[1])
                  form.setFieldValue('img3', urls[2])
                }}
              >
                {imgUrls.length < 3 && '+ Upload'}
              </FormItemUpload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  // 规格尺寸
  const formRender1 = () => {
    return (
      <Form {...formProps}>
        <Row gutter={12}>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.dan-wei')} name="specificationUnit">
              <Radio.Group
                options={SIZE_UNIT.map(v => ({
                  value: v,
                  label: sizeUnitNameMap[v],
                }))}
              />
            </Form.Item>
          </Col>
          <Col {...colNProps}>
            <Form.Item
              label={`${t('LocalProductList.xin-xi')}: ${getUnitText(allValues.specificationUnit, 4).tip}`}
              name="specificationProductInfo"
              validateStatus="success"
              rules={[{ validator: (_r, v) => SizeInput.validate(v, 4) }]}
            >
              <SizeInput unit={allValues.specificationUnit} listLen={4} hideEmptyBtn={formProps.disabled} />
            </Form.Item>
          </Col>
          <Col {...colNProps}>
            <Form.Item
              label={`${t('LocalProductList.zhan-kai-xin-xi')}: ${getUnitText(allValues.specificationUnit, 3).tip}`}
              name="specificationWrapUnfoldInfo"
              validateStatus="success"
              rules={[{ validator: (_r, v) => SizeInput.validate(v, 3) }]}
            >
              <SizeInput unit={allValues.specificationUnit} listLen={3} hideEmptyBtn={formProps.disabled} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  // 仓库复核
  const formRender2 = () => {
    const imgUrls2 = [
      recheckAllValues.imgUrlRecheck,
      recheckAllValues.img2Recheck,
      recheckAllValues.img3Recheck,
    ].filter(Boolean) as string[]
    return (
      <Form
        style={{ marginBottom: 24 }}
        form={recheckForm}
        scrollToFirstError={formProps.scrollToFirstError}
        layout={formProps.layout}
        disabled={!isEmployeeStore}
      >
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item label={t('LocalProductList.bao-cai-tu-pian')}>
              <FormItemUpload
                reqData={{ moduleType: 1 }}
                listType="picture-card"
                maxCount={3}
                accept={fileValidate.IMG_ACCEPT}
                beforeUpload={file => {
                  const msg = fileValidate(file, { accept: fileValidate.IMG_ACCEPT })
                  if (msg) {
                    message.error(msg)
                    return FormItemUpload.LIST_IGNORE
                  }
                }}
                value={imgUrls2}
                onChange={urls => {
                  recheckForm.setFieldValue('imgUrlRecheck', urls[0])
                  recheckForm.setFieldValue('img2Recheck', urls[1])
                  recheckForm.setFieldValue('img3Recheck', urls[2])
                }}
              >
                {imgUrls2.length < 3 && '+ Upload'}
              </FormItemUpload>
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.dan-wei')} name="specificationUnitRecheck">
              <Radio.Group
                options={SIZE_UNIT.map(v => ({
                  value: v,
                  label: sizeUnitNameMap[v],
                }))}
              />
            </Form.Item>
          </Col>
          <Col {...colNProps}>
            <Form.Item
              label={`${t('LocalProductList.xin-xi')}: ${getUnitText(recheckAllValues.specificationUnitRecheck, 4).tip}`}
              name="specificationProductInfoRecheck"
              validateStatus="success"
              rules={[{ validator: (_r, v) => SizeInput.validate(v, 4) }]}
            >
              <SizeInput unit={allValues.specificationUnit} listLen={4} hideEmptyBtn={!isEmployeeStore} />
            </Form.Item>
          </Col>
          <Col {...colNProps}>
            <Form.Item
              label={`${t('LocalProductList.zhan-kai-xin-xi')}: ${getUnitText(allValues.specificationUnit, 3).tip}`}
              name="specificationWrapUnfoldInfoRecheck"
              validateStatus="success"
              rules={[{ validator: (_r, v) => SizeInput.validate(v, 3) }]}
            >
              <SizeInput unit={allValues.specificationUnit} listLen={3} hideEmptyBtn={!isEmployeeStore} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  // 操作
  const actionRender = () => {
    return (
      <>
        {!record && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={async () => {
              form.submit() // 触发 scrollToFirstError
              await form.validateFields()
              Modal.confirm({
                title: t('LocalProductList.que-ren-yao-xin-zeng-ma'),
                onOk: async () => {
                  await apiAddOrEdit(allValues)
                  message.success(t('2-common.cao-zuo-cheng-gong'))
                  onSuccess?.()
                },
              })
            }}
          >
            {t('2-common.xin-zeng')}
          </Button>
        )}
        {record && (
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={async () => {
              if (isEmployeeStore) {
                recheckForm.submit() // 触发 scrollToFirstError
                let values = await recheckForm.validateFields()
                values = recheckForm.getFieldsValue(true)
                if (!skuId) return
                Modal.confirm({
                  title: t('LocalProductList.que-ren-yao-bao-cun-ma'),
                  onOk: async () => {
                    await apiRecheck(values, skuId)
                    message.success(t('2-common.cao-zuo-cheng-gong'))
                    onSuccess?.()
                  },
                })
                return
              } else {
                form.submit() // 触发 scrollToFirstError
                await form.validateFields()
                if (!skuId) return
                Modal.confirm({
                  title: t('LocalProductList.que-ren-yao-bao-cun-ma'),
                  onOk: async () => {
                    await apiAddOrEdit({ ...allValues, packingMaterialType: undefined }, skuId)
                    message.success(t('2-common.cao-zuo-cheng-gong'))
                    onSuccess?.()
                  },
                })
              }
            }}
          >
            {t('2-common.bao-cun')}
          </Button>
        )}
      </>
    )
  }

  return (
    <div className={styles.box}>
      <div className={styles.main}>
        {fetching ? (
          <Loading />
        ) : (
          <>
            <Anchor
              className={styles.main_left}
              affix={false}
              getContainer={() => scrollRef.current || window}
              onClick={e => e.preventDefault()}
              items={[
                t('LocalProductList.ji-chu-xin-xi'),
                t('LocalProductList.gui-ge-chi-cun'),
                t('LocalProductList.cang-ku-fu-he'),
              ].map((name, index) => ({
                key: name,
                href: `#${idPrefix}_${index}`,
                title: name,
              }))}
            />
            <div className={styles.main_right} ref={scrollRef}>
              <section id={`${idPrefix}_0`}>
                <Divider orientation="left" orientationMargin={0}>
                  <b>{t('LocalProductList.ji-chu-xin-xi')}</b>
                </Divider>
                {formRender0()}
              </section>

              <section id={`${idPrefix}_1`}>
                <Divider orientation="left" orientationMargin={0}>
                  <b>{t('LocalProductList.gui-ge-chi-cun')}</b>
                </Divider>
                {formRender1()}
              </section>

              <section id={`${idPrefix}_2`}>
                <Divider orientation="left" orientationMargin={0}>
                  <b>{t('LocalProductList.cang-ku-fu-he')}</b>
                </Divider>
                {formRender2()}
              </section>
            </div>
          </>
        )}
      </div>
      <div className={styles.footer}>
        <Space>
          <Button onClick={() => onReturn()}>{t('2-common.fan-hui')}</Button>
          {actionRender()}
        </Space>
      </div>
    </div>
  )
}
