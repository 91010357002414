import { useEffect, useId, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import {
  Anchor,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  FormProps,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
} from 'antd'
import { PlusOutlined, SaveOutlined } from '@ant-design/icons'

import { FormItemUpload } from '@/components/FormItemUpload'
import { Loading } from '@/components/Loading'
import { useFormAllValues } from '@/hooks/hooks'
import { fileValidate, formAutoTrimOnBlur, selectFilterOption, toThousands } from '@/utils/utils'
import { SupplierSelect } from '../../components/SupplierSelect'
import { apiAddOrEdit, apiGetDetail, apiRecheck } from '../apis'
import { IDetail, IRecord, TFormValues, TRecheckFormValues } from '../interface'
import {
  CATEGORY,
  getUnitText,
  LOGISTICS_ATTRIBUTE,
  SIZE_UNIT,
  useCATEGORY_NAME_MAP,
  useLOGISTICS_ATTRIBUTE_NAME_MAP,
  useSIZE_UNIT_NAME_MAP,
  useWRAP_TYPE_NAME_MAP,
  WRAP_TYPE,
} from '../utils'
import { amountDeclaresRender } from './amountDeclaresRender'
import { singleProductInfosRender } from './singleProductInfosRender'
import { SizeInput } from './SizeInput'
import { useRecheckForm } from './useRecheckForm'
import { useWrapProductList } from './useWrapProductList'
import { createInitialFormValues, createSendValues, textInputFields } from './utils'
import styles from './styles.module.less'

/** 产品详情 */
export const Dedail = ({
  isEmployee,
  isEmployeeStore,
  addProductType,
  record,
  onReturn,
  onSuccess,
}: {
  /** 员工侧 */
  isEmployee: boolean
  /** 员工侧-仓库 */
  isEmployeeStore?: boolean
  /** 新增类型(仅新增，必传) */
  addProductType?: number | null
  /** 有值则视为编辑 */
  record: IRecord | null
  /** 返回 */
  onReturn: () => void
  /** 新增或编辑成功回调 */
  onSuccess?: () => void
}) => {
  const { t } = useTranslation()
  const isEdit = !!record
  const skuId = record?.skuId
  const productType = record?.productType ?? addProductType ?? 1
  const idPrefix = useId()
  const scrollRef = useRef<HTMLDivElement>(null)

  const [fetching, setFetching] = useState(isEdit && !!skuId)
  const [detail, setDetail] = useState<IDetail | null>(null)
  const [form] = Form.useForm<TFormValues>()
  const initialFormValues = useMemo(() => createInitialFormValues(productType, null), [productType])
  const allValues = useFormAllValues(form, initialFormValues)

  const { recheckForm, initialRecheckFormValues, recheckAllValues } = useRecheckForm()

  const formProps: FormProps<TFormValues> = {
    form,
    scrollToFirstError: { block: 'center', behavior: 'smooth' },
    layout: 'vertical',
    disabled: isEmployeeStore,
    preserve: true,
    initialValues: initialFormValues,
    onBlur: e => formAutoTrimOnBlur({ event: e, form, includeField: textInputFields }),
  }

  const col1Props = { xxl: 6, sm: 8, xs: 8 }
  const col2Props = { xxl: 12, sm: 16, xs: 16 }
  const categoryNameMap = useCATEGORY_NAME_MAP()
  const logisticsAttributeNameMap = useLOGISTICS_ATTRIBUTE_NAME_MAP()
  const sizeUnitNameMap = useSIZE_UNIT_NAME_MAP()
  const wrapTypeNameMap = useWRAP_TYPE_NAME_MAP()
  const imgUrls = [allValues.imgUrl, allValues.img2, allValues.img3].filter(Boolean) as string[]

  const { wrapProductList } = useWrapProductList(isEdit, detail?.merchantUserId)

  useEffect(() => {
    if (!isEdit || !skuId) return
    setFetching(true)
    apiGetDetail(skuId)
      .then(res => {
        const { localProductRecheckVo, ...rest } = res
        const values = createInitialFormValues(productType, rest)
        setDetail(res)
        form.setFieldsValue(values)
        recheckForm.setFieldsValue(
          _.mapValues(
            {
              ...initialRecheckFormValues,
              ...localProductRecheckVo,
              merchantUserId: rest.merchantUserId,
            },
            (v, k) => {
              if (k === 'logisticsAttributeRecheck') return v ?? []
              return v ?? undefined
            },
          ) as TRecheckFormValues,
        )
      })
      .finally(() => setFetching(false))
  }, [form, initialRecheckFormValues, isEdit, productType, recheckForm, skuId])

  // 基础信息
  const formRender0 = () => {
    return (
      <Form {...formProps}>
        <Row gutter={12}>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.chan-pin-id')}>
              <span style={{ marginLeft: 3 }}>{detail?.skuId || '--'}</span>
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label="SKU" name="sku" rules={[{ required: true }]}>
              <Input disabled={formProps.disabled || isEdit} allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label="SPU" name="spu">
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item
              label={t('LocalProductList.ying-wen-pin-ming')}
              name="productEnTitle"
              rules={[{ required: true }]}
            >
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.zhong-wen-pin-ming')} name="productCTitle">
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.lei-mu')} name="categoryCode">
              <Select
                placeholder={t('2-common.qing-xuan-ze')}
                allowClear
                options={CATEGORY.map(v => ({ value: v, label: categoryNameMap[v] }))}
              />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.gong-ying-shang')} name="sysSupplierIdList">
              <SupplierSelect
                style={{ maxWidth: 'auto' }}
                merchantUserId={isEmployee ? `${detail?.merchantUserId}` : undefined}
                multiple
                placeholder={t('2-common.qing-xuan-ze')}
              />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.upc-ma')} name="upc">
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.fnsku-ma')} name="fnsku">
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.qi-ta-chan-pin-tiao-ma')} name="otherCode">
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.xi-tong-sheng-cheng-chan-pin-tiao-ma')}>
              <span style={{ marginLeft: 3 }}>{detail?.sysBarCode || '--'}</span>
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.xing-hao')} name="model">
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.pin-pai')} name="brand">
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.cai-gou-jia-ge')} name="purchasePrice">
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                precision={2}
                stringMode
                formatter={v => toThousands(v, { placeholder: '', precision: null })}
                addonAfter="USD"
                placeholder={t('2-common.qing-shu-ru')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t('LocalProductList.chan-pin-tu-pian')}>
              <FormItemUpload
                reqData={{ moduleType: 1 }}
                listType="picture-card"
                maxCount={3}
                accept={fileValidate.IMG_ACCEPT}
                beforeUpload={file => {
                  const msg = fileValidate(file, { accept: fileValidate.IMG_ACCEPT })
                  if (msg) {
                    message.error(msg)
                    return FormItemUpload.LIST_IGNORE
                  }
                }}
                value={imgUrls}
                onChange={urls => {
                  form.setFieldValue('imgUrl', urls[0])
                  form.setFieldValue('img2', urls[1])
                  form.setFieldValue('img3', urls[2])
                }}
              >
                {imgUrls.length < 3 && '+ Upload'}
              </FormItemUpload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  // 物流属性
  const formRender1 = () => {
    return (
      <Form {...formProps}>
        <Form.Item name="logisticsAttributeList">
          <Checkbox.Group
            options={LOGISTICS_ATTRIBUTE.map(v => ({
              value: v,
              label: logisticsAttributeNameMap[v],
              disabled: (() => {
                const arr = allValues.logisticsAttributeList
                if (v === 9 && arr.includes(10)) return true
                if (v === 10 && arr.includes(9)) return true
                if (v === 11 && arr.includes(12)) return true
                if (v === 12 && arr.includes(11)) return true
              })(),
            }))}
          />
        </Form.Item>
      </Form>
    )
  }

  // 规格尺寸
  const formRender2 = () => {
    return (
      <Form {...formProps}>
        <Row gutter={12}>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.dan-wei')} name="specificationUnit">
              <Radio.Group
                options={SIZE_UNIT.map(v => ({
                  value: v,
                  label: sizeUnitNameMap[v],
                }))}
              />
            </Form.Item>
          </Col>
          <Col {...col2Props}>
            <Form.Item
              label={`${t('LocalProductList.xin-xi')}: ${getUnitText(allValues.specificationUnit, 4).tip}`}
              name="specificationProductInfo"
              validateStatus="success"
              rules={[{ validator: (_r, v) => SizeInput.validate(v, 4) }]}
            >
              <SizeInput unit={allValues.specificationUnit} listLen={4} hideEmptyBtn={formProps.disabled} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  // 包装信息
  const formRender3 = () => {
    return (
      <Form {...formProps}>
        <Row gutter={12}>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.dan-wei')} name="wrapUnit">
              <Radio.Group
                options={SIZE_UNIT.map(v => ({
                  value: v,
                  label: sizeUnitNameMap[v],
                }))}
              />
            </Form.Item>
          </Col>
          <Col {...col2Props}>
            <Form.Item
              label={`${t('LocalProductList.xin-xi')}: ${getUnitText(allValues.wrapUnit, 4).tip}`}
              name="wrapProductInfo"
              validateStatus="success"
              rules={[{ validator: (_r, v) => SizeInput.validate(v, 4) }]}
            >
              <SizeInput unit={allValues.wrapUnit} listLen={4} hideEmptyBtn={formProps.disabled} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.mo-ren-bao-cai')} name="defaultPackingMaterialSkuId">
              <Select
                allowClear
                placeholder={t('2-common.qing-xuan-ze')}
                showSearch
                filterOption={selectFilterOption}
                options={wrapProductList.map(d => ({
                  value: d.skuId,
                  label: d.sku || d.skuId,
                }))}
              />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.bao-zhuang-lei-xing')} name="wrapType">
              <Select
                placeholder={t('2-common.qing-xuan-ze')}
                allowClear
                options={WRAP_TYPE.map(v => ({
                  value: v,
                  label: wrapTypeNameMap[v],
                }))}
              />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label="Ready2Ship" name="readyShip">
              <Radio.Group
                options={[
                  { value: 1, label: t('2-common.shi') },
                  { value: 0, label: t('2-common.fou') },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  // 申报信息
  const formRender4 = () => {
    return (
      <Form {...formProps}>
        <Row gutter={12}>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.hai-guan-bian-ma')} name="hsCode">
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.cai-zhi-ying-wen')} name="ematerial">
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.cai-zhi-zhong-wen')} name="cmaterial">
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.yong-tu-ying-wen')} name="epurpose">
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.yong-tu-zhong-wen')} name="cpurpose">
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.qi-ta-shen-bao-yao-su')} name="otherDeclarationElements">
              <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  // 申报金额
  const formRender5 = () => {
    return (
      <Form {...formProps} style={{ marginBottom: 24 }}>
        {amountDeclaresRender(form, allValues, !!isEmployeeStore)}
      </Form>
    )
  }

  // 组合清单
  const formRender6 = () => {
    return (
      <Form {...formProps} disabled={formProps.disabled || isEdit} style={{ marginBottom: 24 }}>
        {singleProductInfosRender(form, allValues, !!isEmployeeStore || isEdit)}
      </Form>
    )
  }

  // 仓库复核
  const formRender7 = () => {
    const imgUrls2 = [
      recheckAllValues.imgUrlRecheck,
      recheckAllValues.img2Recheck,
      recheckAllValues.img3Recheck,
    ].filter(Boolean) as string[]
    return (
      <Form
        style={{ marginBottom: 24 }}
        form={recheckForm}
        scrollToFirstError={formProps.scrollToFirstError}
        layout={formProps.layout}
        disabled={!isEmployeeStore}
      >
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item label={t('LocalProductList.chan-pin-tu-pian')}>
              <FormItemUpload
                reqData={{ moduleType: 1 }}
                listType="picture-card"
                maxCount={3}
                accept={fileValidate.IMG_ACCEPT}
                beforeUpload={file => {
                  const msg = fileValidate(file, { accept: fileValidate.IMG_ACCEPT })
                  if (msg) {
                    message.error(msg)
                    return FormItemUpload.LIST_IGNORE
                  }
                }}
                value={imgUrls2}
                onChange={urls => {
                  recheckForm.setFieldValue('imgUrlRecheck', urls[0])
                  recheckForm.setFieldValue('img2Recheck', urls[1])
                  recheckForm.setFieldValue('img3Recheck', urls[2])
                }}
              >
                {imgUrls2.length < 3 && '+ Upload'}
              </FormItemUpload>
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.dan-wei')} name="specificationUnitRecheck">
              <Radio.Group
                options={SIZE_UNIT.map(v => ({
                  value: v,
                  label: sizeUnitNameMap[v],
                }))}
              />
            </Form.Item>
          </Col>
          <Col {...col2Props}>
            <Form.Item
              label={`${t('LocalProductList.xin-xi')}: ${getUnitText(recheckAllValues.specificationUnitRecheck, 4).tip}`}
              name="specificationProductInfoRecheck"
              validateStatus="success"
              rules={[{ validator: (_r, v) => SizeInput.validate(v, 4) }]}
            >
              <SizeInput unit={allValues.specificationUnit} listLen={4} hideEmptyBtn={!isEmployeeStore} />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label={t('LocalProductList.bao-zhuang-lei-xing')} name="wrapTypeRecheck">
              <Select
                placeholder={t('2-common.qing-xuan-ze')}
                allowClear
                options={WRAP_TYPE.map(v => ({
                  value: v,
                  label: wrapTypeNameMap[v],
                }))}
              />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label="Ready2Ship" name="readyShipRecheck">
              <Radio.Group
                options={[
                  { value: 1, label: t('2-common.shi') },
                  { value: 0, label: t('2-common.fou') },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="logisticsAttributeRecheck">
              <Checkbox.Group
                options={LOGISTICS_ATTRIBUTE.map(v => ({
                  value: v,
                  label: logisticsAttributeNameMap[v],
                  disabled: (() => {
                    const arr = recheckAllValues.logisticsAttributeRecheck
                    if (v === 9 && arr.includes(10)) return true
                    if (v === 10 && arr.includes(9)) return true
                    if (v === 11 && arr.includes(12)) return true
                    if (v === 12 && arr.includes(11)) return true
                  })(),
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  // 操作
  const actionRender = () => {
    return (
      <>
        {!record && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={async () => {
              form.submit() // 触发 scrollToFirstError
              await form.validateFields()
              if (allValues.productType === 3 && allValues.singleProductInfos.length < 2) {
                return message.error(t('LocalProductList.zu-he-qing-dan-zhi-shao-yao-xuan-ze-liang-ge-chan-pin'))
              }
              Modal.confirm({
                title: t('LocalProductList.que-ren-yao-xin-zeng-ma'),
                onOk: async () => {
                  await apiAddOrEdit(createSendValues(allValues))
                  message.success(t('2-common.cao-zuo-cheng-gong'))
                  onSuccess?.()
                },
              })
            }}
          >
            {t('2-common.xin-zeng')}
          </Button>
        )}
        {record && (
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={async () => {
              if (isEmployeeStore) {
                recheckForm.submit() // 触发 scrollToFirstError
                let values = await recheckForm.validateFields()
                values = recheckForm.getFieldsValue(true)
                if (!skuId) return
                Modal.confirm({
                  title: t('LocalProductList.que-ren-yao-bao-cun-ma'),
                  onOk: async () => {
                    await apiRecheck(values, skuId)
                    message.success(t('2-common.cao-zuo-cheng-gong'))
                    onSuccess?.()
                  },
                })
                return
              }
              form.submit() // 触发 scrollToFirstError
              await form.validateFields()
              if (!skuId) return
              Modal.confirm({
                title: t('LocalProductList.que-ren-yao-bao-cun-ma'),
                onOk: async () => {
                  await apiAddOrEdit(createSendValues(allValues), skuId)
                  message.success(t('2-common.cao-zuo-cheng-gong'))
                  onSuccess?.()
                },
              })
            }}
          >
            {t('2-common.bao-cun')}
          </Button>
        )}
      </>
    )
  }

  return (
    <div className={styles.box}>
      <div className={styles.main}>
        {fetching ? (
          <Loading />
        ) : (
          <>
            <Anchor
              className={styles.main_left}
              affix={false}
              getContainer={() => scrollRef.current || window}
              onClick={e => e.preventDefault()}
              items={[
                t('LocalProductList.ji-chu-xin-xi'),
                t('LocalProductList.wu-liu-shu-xing'),
                t('LocalProductList.gui-ge-chi-cun'),
                t('LocalProductList.bao-zhuang-xin-xi'),
                t('LocalProductList.shen-bao-xin-xi'),
                t('LocalProductList.shen-bao-jin-e'),
                ...(allValues.productType === 3 ? [t('LocalProductList.zu-he-qing-dan')] : []),
                t('LocalProductList.cang-ku-fu-he'),
              ].map((name, index) => ({
                key: name,
                href: `#${idPrefix}_${index}`,
                title: name,
              }))}
            />
            <div className={styles.main_right} ref={scrollRef}>
              <section id={`${idPrefix}_0`}>
                <Divider orientation="left" orientationMargin={0}>
                  <b>{t('LocalProductList.ji-chu-xin-xi')}</b>
                </Divider>
                {formRender0()}
              </section>

              <section id={`${idPrefix}_1`}>
                <Divider orientation="left" orientationMargin={0}>
                  <b>{t('LocalProductList.wu-liu-shu-xing')}</b>
                </Divider>
                {formRender1()}
              </section>

              <section id={`${idPrefix}_2`}>
                <Divider orientation="left" orientationMargin={0}>
                  <b>{t('LocalProductList.gui-ge-chi-cun')}</b>
                </Divider>
                {formRender2()}
              </section>

              <section id={`${idPrefix}_3`}>
                <Divider orientation="left" orientationMargin={0}>
                  <b>{t('LocalProductList.bao-zhuang-xin-xi')}</b>
                </Divider>
                {formRender3()}
              </section>

              <section id={`${idPrefix}_4`}>
                <Divider orientation="left" orientationMargin={0}>
                  <b>{t('LocalProductList.shen-bao-xin-xi')}</b>
                </Divider>
                {formRender4()}
              </section>

              <section id={`${idPrefix}_5`}>
                <Divider orientation="left" orientationMargin={0}>
                  <b>{t('LocalProductList.shen-bao-jin-e')}</b>
                </Divider>
                {formRender5()}
              </section>

              {allValues.productType === 3 && (
                <section id={`${idPrefix}_6`}>
                  <Divider orientation="left" orientationMargin={0}>
                    <b>{t('LocalProductList.zu-he-qing-dan')}</b>
                  </Divider>
                  {formRender6()}
                </section>
              )}

              <section id={`${idPrefix}_7`}>
                <Divider orientation="left" orientationMargin={0}>
                  <b>{t('LocalProductList.cang-ku-fu-he')}</b>
                </Divider>
                {formRender7()}
              </section>
            </div>
          </>
        )}
      </div>
      <div className={styles.footer}>
        <Space>
          <Button onClick={() => onReturn()}>{t('2-common.fan-hui')}</Button>
          {actionRender()}
        </Space>
      </div>
    </div>
  )
}
