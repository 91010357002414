import { useMemo } from 'react'
import { Form } from 'antd'

import { useFormAllValues } from '@/hooks/hooks'
import { TRecheckFormValues } from '../interface'

export const useRecheckForm = () => {
  const [recheckForm] = Form.useForm<TRecheckFormValues>()

  const initialRecheckFormValues = useMemo<TRecheckFormValues>(
    () => ({
      imgUrlRecheck: undefined,
      img2Recheck: undefined,
      img3Recheck: undefined,
      specificationUnitRecheck: undefined,
      specificationProductInfoRecheck: undefined,
      specificationWrapUnfoldInfoRecheck: undefined,
      wrapTypeRecheck: undefined,
      readyShipRecheck: undefined,
      logisticsAttributeRecheck: [],
      merchantUserId: undefined,
    }),
    [],
  )

  const recheckAllValues = useFormAllValues(recheckForm, initialRecheckFormValues as TRecheckFormValues)

  return {
    recheckForm,
    initialRecheckFormValues,
    recheckAllValues,
  }
}
