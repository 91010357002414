import { IDetail, TFormValues } from '../interface'

/** 文本输入字段 */
export const textInputFields = [
  'sku',
  'spu',
  'productEnTitle',
  'productCTitle',
  'upc',
  'fnsku',
  'otherCode',
  'model',
  'brand',
  'purchasePrice',
  'hsCode',
  'ematerial',
  'cmaterial',
  'epurpose',
  'cpurpose',
  'otherDeclarationElements',
]

/** 根据详情创建初始的表单数据 */
export const createInitialFormValues = (
  productType: number,
  data: IDetail | null,
  _showPlaceholderWhenEmpty = true,
): TFormValues => {
  // 默认值尽可能使用空串
  const values: TFormValues = {
    productType,

    sku: '',
    spu: '',
    productEnTitle: '',
    productCTitle: '',
    categoryCode: '',
    sysSupplierIdList: [],
    upc: '',
    fnsku: '',
    otherCode: '',
    sysBarCode: '',
    model: '',
    brand: '',
    purchasePrice: '',
    packingMaterialType: undefined,
    imgUrl: '',
    img2: '',
    img3: '',

    logisticsAttributeList: [],

    specificationUnit: 1,
    specificationProductInfo: '',
    specificationWrapUnfoldInfo: '',

    wrapUnit: 1,
    wrapProductInfo: '',
    defaultPackingMaterialSkuId: '',
    wrapType: undefined,
    readyShip: 0,

    hsCode: '',
    ematerial: '',
    cmaterial: '',
    epurpose: '',
    cpurpose: '',
    otherDeclarationElements: '',
    amountDeclares: [],
    singleProductInfos: [],

    merchantUserId: '',
  }

  if (data) {
    const vals = values as any
    const dat = data as any
    Object.keys(vals).forEach(key => {
      if (key === 'productType') return
      if (key === 'sysSupplierIdList') {
        if (data.supplierList) {
          values.sysSupplierIdList = data.supplierList.map(d => d.sysSupplierId) // 赋值
        }
        return
      }
      if (dat[key] != null) {
        vals[key] = dat[key] // 赋值
      }
    })
  }

  // 解决 Select 值为空串不显示 placeholder 问题
  if (_showPlaceholderWhenEmpty) {
    values.categoryCode = values.categoryCode || undefined
    values.defaultPackingMaterialSkuId = values.defaultPackingMaterialSkuId || undefined
  }

  return values
}

/** 根据表单数据创建提交数据 */
export const createSendValues = ({ ...formValues }: TFormValues): TFormValues => {
  const emptyValues = createInitialFormValues(formValues.productType, null, false)
  Object.entries(emptyValues).forEach(([key, val]) => {
    if (typeof val === 'string') {
      const obj = formValues as any
      obj[key] = obj[key] || ''
    }
  })
  formValues.wrapType = formValues.wrapType ?? null // null 表示空值
  return formValues
}
