import { useEffect, useMemo } from 'react'
import { useRequest } from 'ahooks'

import { getGlobalState } from '@/globalStore/globalStore'
import { apiGetRecords } from '../apis'
import { IRecord } from '../interface'

/** 获取商家包材列表 */
export const useWrapProductList = (
  isEdit: boolean,
  merchantUserId: string | null | undefined,
): {
  /** 商家包材列表 */
  wrapProductList: IRecord[]
} => {
  const { data, run } = useRequest<IRecord[], [string]>(
    id =>
      apiGetRecords({
        productType: [2],
        merchantUserIds: [id],
        isRecordStock: 1,
        page: 1,
        size: 199,
      }).then(res => res.records),
    {
      manual: true,
    },
  )

  useEffect(() => {
    if (isEdit) {
      if (merchantUserId) run(merchantUserId)
    } else {
      run(`${getGlobalState().userInfo?.userId}`)
    }
  }, [isEdit, merchantUserId, run])

  return useMemo(
    () => ({
      wrapProductList: data || [],
    }),
    [data],
  )
}
