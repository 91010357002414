import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import dayjs from 'dayjs'
import { Button, Dropdown, Input, message, Modal, Popover, Space } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { DownloadOutlined, DownOutlined, InfoCircleOutlined } from '@ant-design/icons'

import { CusImage } from '@/components/CusImage'
import { CusTable, TCusTableIns } from '@/components/CusTable'
import { SPIN_DELAY } from '@/components/Loading'
import { useControllerRef } from '@/hooks/useAbortController'
import { filePick } from '@/utils/filePick'
import { DEFAULT_PAGE_SIZE, getDefaultPaginationProps } from '@/utils/pagination'
import { fileValidate } from '@/utils/utils'
import { MerchantSelect } from '../components/MerchantSelect'
import { SuccessAndFailResultModal } from '../components/SuccessAndFailResultModal'
import { apiGetRecords, apiImport } from './apis'
import { Dedail } from './Dedail'
import { DedailWrap } from './Dedail/index.wrap'
import { IRecord, IReqData, IRes } from './interface'
import { ProcessOrderModal } from './ProcessOrderModal'
import { popupSlot } from './utils'
import styles from './styles.module.less'

/** 本地产品列表 */
export const LocalProductList = (props: { isEmployee?: boolean; isEmployeeStore?: boolean }) => {
  const { isEmployee } = props
  let { isEmployeeStore } = props
  if (!isEmployee) isEmployeeStore = false
  return <ProductList {...props} isEmployeeStore={isEmployeeStore} isWrapProduct={false} />
}

/** 包材列表 */
export const WrapProductList = (props: { isEmployee?: boolean; isEmployeeStore?: boolean }) => {
  const { isEmployee } = props
  let { isEmployeeStore } = props
  if (!isEmployee) isEmployeeStore = false
  return <ProductList {...props} isEmployeeStore={isEmployeeStore} isWrapProduct={true} />
}

function ProductList({
  isWrapProduct,
  isEmployee = false,
  isEmployeeStore = false,
}: {
  isWrapProduct: boolean
  isEmployee?: boolean
  isEmployeeStore?: boolean
}) {
  const { t } = useTranslation()
  const productType = isWrapProduct ? [2] : [1, 3]
  const [sku, setSku] = useState<IReqData['sku']>()
  const [spu, setSpu] = useState<IReqData['spu']>()
  const [productEnTitle, setProductEnTitle] = useState<IReqData['productEnTitle']>()
  const [merchantUserIds, setMerchantUserIds] = useState<IReqData['merchantUserIds']>()
  const [page, setPage] = useState<IReqData['page']>(1)
  const [size, setSize] = useState<IReqData['size']>(DEFAULT_PAGE_SIZE)

  const [showDetail, setShowDetail] = useState<false | 1 | 2 | 3 | IRecord>(false) // 1-新增产品; 2-新增包材; 3:新增组合产品; IRecord-详情或编辑;
  const DedailComp = isWrapProduct ? DedailWrap : Dedail

  const reqData: IReqData = { sku, spu, productEnTitle, merchantUserIds, page, size }
  const tableRef = useRef<TCusTableIns>(null)
  const controllerRef = useControllerRef()
  const {
    loading,
    data,
    run: getList,
    refresh,
  } = useRequest<IRes, [IReqData]>(
    req => {
      controllerRef.current.abort()
      controllerRef.current = new AbortController()
      return apiGetRecords({ ...req, productType }, controllerRef.current.signal)
    },
    {
      defaultParams: [reqData],
      onBefore: () => CusTable.scrollToTop(tableRef.current),
    },
  )

  const handleImport = () => {
    filePick({
      accept: '.xlsx',
      onFilePick: ([file]) => {
        const msg = fileValidate(file, { accept: '.xlsx', max: 10 })
        if (msg) return void message.error(msg)

        Modal.confirm({
          title: t('LocalProductList.que-ren-yao-dao-ru-ma'),
          content: file.name,
          onOk: async () => {
            const result = await apiImport(isWrapProduct ? 2 : 1, file)
            SuccessAndFailResultModal.open({
              result,
              onClose: () => {
                if (result.successTotal > 0) {
                  setPage(1)
                  getList({ ...reqData, page: 1 })
                }
              },
            })
          },
        })
      },
    })
  }

  const columns: ColumnsType<IRecord> = [
    {
      title: `${isWrapProduct ? t('LocalProductList.bao-cai') : t('LocalProductList.chan-pin')}ID`,
      render: (_v, { skuId: v }) => v,
    },
    {
      title: `${isWrapProduct ? t('LocalProductList.bao-cai') : t('LocalProductList.chan-pin')}${t('LocalProductList.tu-pian')}`,
      render: (_v, { imgUrl: v }) => <CusImage height={50} width={50} src={v || CusImage.defaultImg} />,
    },
    {
      title: 'SKU',
      render: (_v, { sku: v }) => v,
    },
    {
      title: 'SPU',
      hidden: isWrapProduct,
      render: (_v, { spu: v }) => v,
    },
    {
      title: t('LocalProductList.ying-wen-pin-ming'),
      render: (_v, { productEnTitle: v }) => v,
    },
    {
      title: t('2-common.shang-jia-ming-cheng'),
      hidden: !isEmployee,
      render: (_v, { merchantUserName: v }) => v,
    },
    {
      title: t('2-common.chuang-jian-shi-jian'),
      width: 110,
      render: (_v, { createdTime: v }) => v && dayjs(v).format($F_YMD),
    },
    {
      title: t('2-common.cao-zuo'),
      width: 175,
      render: (_v, record) => {
        return (
          <>
            <Button type="link" onClick={() => setShowDetail(record)}>
              {t('2-common.bian-ji')}
            </Button>
            {!isEmployee && record.productType === 3 && (
              <Button type="link" onClick={() => ProcessOrderModal.open({ record })}>
                {t('LocalProductList.fa-qi-jia-gong-dan')}
              </Button>
            )}
          </>
        )
      },
    },
  ]

  const handlePressEnter = () => {
    setPage(1)
    getList({ ...reqData, page: 1 })
  }

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <div className={styles.header_filter}>
          <Space wrap>
            <Input
              placeholder={t('2-common.qing-shu-ru-guan-jian-ci')}
              allowClear
              addonBefore="SKU"
              value={sku}
              onChange={e => setSku(e.target.value)}
              onPressEnter={handlePressEnter}
            />
            {!isWrapProduct && (
              <Input
                placeholder={t('2-common.qing-shu-ru-guan-jian-ci')}
                allowClear
                addonBefore="SPU"
                value={spu}
                onChange={e => setSpu(e.target.value)}
                onPressEnter={handlePressEnter}
              />
            )}
            <Space>
              <Input
                placeholder={t('2-common.qing-shu-ru-guan-jian-ci')}
                allowClear
                addonBefore={t('LocalProductList.ying-wen-pin-ming')}
                value={productEnTitle}
                onChange={e => setProductEnTitle(e.target.value)}
                onPressEnter={handlePressEnter}
              />
              {isEmployee && (
                <MerchantSelect
                  style={{ width: 160 }}
                  value={merchantUserIds?.[0]}
                  onChange={val => {
                    const merchantUserIds = val ? [val] : undefined
                    setMerchantUserIds(merchantUserIds)
                    setPage(1)
                    getList({ ...reqData, merchantUserIds, page: 1 })
                  }}
                />
              )}
              <Space size={4}>
                <Button
                  type="primary"
                  onClick={() => {
                    setPage(1)
                    getList({ ...reqData, page: 1 })
                  }}
                >
                  {t('2-common.sou-suo')}
                </Button>
                <Button
                  onClick={() => {
                    const vo = undefined
                    setSku(vo)
                    !isWrapProduct && setSpu(vo)
                    setProductEnTitle(vo)
                    setMerchantUserIds(vo)
                    setPage(1)
                    getList({
                      ...reqData,
                      sku: vo,
                      spu: vo,
                      productEnTitle: vo,
                      merchantUserIds: vo,
                      page: 1,
                    })
                  }}
                >
                  {t('2-common.zhong-zhi')}
                </Button>
              </Space>
            </Space>
          </Space>
        </div>
        {!isEmployee && (
          <div className={styles.header_action}>
            <Space>
              {isWrapProduct ? (
                <Button type="primary" onClick={() => setShowDetail(2)}>
                  {t('2-common.xin-zeng')}
                  {t('LocalProductList.bao-cai')}
                </Button>
              ) : (
                <Dropdown
                  menu={{
                    items: [
                      { key: 1, label: t('LocalProductList.xin-zeng-dan-ge-chan-pin') },
                      { key: 3, label: t('LocalProductList.xin-zeng-zu-he-chan-pin') },
                    ],
                    onClick: e => setShowDetail(+e.key as 1 | 3),
                  }}
                >
                  <Button type="primary">
                    <Space>
                      {t('LocalProductList.xin-zeng-chan-pin')}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              )}
              <Popover
                mouseLeaveDelay={0.2}
                placement="topRight"
                content={
                  <Button
                    type="link"
                    icon={<DownloadOutlined />}
                    onClick={() => {
                      const origin = `https://shipo-erp.oss-us-east-1.aliyuncs.com`
                      let path = `/excelTemplate/LocalProductTemplate.xlsx`
                      if (isWrapProduct) path = `/excelTemplate/PackagingMaterialTemplate.xlsx`
                      window.open(`${origin}${path}`)
                    }}
                  >
                    {t('2-common.excel-mo-ban-xia-zai')}
                  </Button>
                }
              >
                <Button type="primary" onClick={handleImport}>
                  {t('2-common.excel-dao-ru')}
                  <InfoCircleOutlined />
                </Button>
              </Popover>
            </Space>
          </div>
        )}
      </div>

      <div className={styles.table}>
        <CusTable
          rowKey="skuId"
          loading={{ spinning: loading, delay: SPIN_DELAY }}
          dataSource={data?.records}
          columns={columns}
          ref={tableRef}
          verticalFlexLayout
          pagination={{
            ...getDefaultPaginationProps(data?.total || 0),
            current: page,
            pageSize: size,
            onChange: (page, size) => {
              setPage(page)
              setSize(size)
              getList({ ...reqData, page, size })
            },
          }}
        />
      </div>

      {showDetail && (
        <DedailComp
          isEmployee={isEmployee}
          isEmployeeStore={isEmployeeStore}
          addProductType={typeof showDetail === 'number' ? showDetail : null}
          record={typeof showDetail === 'object' ? showDetail : null}
          onReturn={() => setShowDetail(false)}
          onSuccess={() => {
            setShowDetail(false)
            if (typeof showDetail === 'number') {
              setPage(1)
              getList({ ...reqData, page: 1 })
            } else {
              refresh()
            }
          }}
        />
      )}

      <popupSlot.Slot />
    </div>
  )
}
