import { t } from 'i18next'
import { Button, Form, FormInstance, InputNumber, Popconfirm } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import { CusTable } from '@/components/CusTable'
import { LocalProductCell } from '../../components/LocalProductCell'
import { LocalProductPickModal } from '../../components/LocalProductPick'
import { TFormValues } from '../interface'

export const singleProductInfosRender = (
  _form: FormInstance<TFormValues>,
  allValues: TFormValues,
  disabledBtn: boolean,
) => {
  const rowIdPrefix = 'LocalProductList_Dedail_singleProductInfos_'
  return (
    <Form.List name="singleProductInfos">
      {(fields, { add, remove }) => (
        <CusTable
          locale={{ emptyText: <div style={{ padding: 6 }}>{t('2-common.zan-wu-shu-ju')}</div> }}
          rowKey="skuId"
          dataSource={allValues.singleProductInfos}
          onRow={(_d, i) => ({ id: `${rowIdPrefix}${i}` })}
          columns={[
            {
              title: t('LocalProductList.chan-pin-xin-xi'),
              width: '50%',
              render: (_v, r) => (
                <LocalProductCell img={r.imgUrl} sku={r.sku} skuId={r.skuId} title={r.productEnTitle} />
              ),
            },
            {
              title: t('LocalProductList.chan-pin-shu-liang'),
              width: '25%',
              render: (_v, _r, index) => {
                return (
                  <Form.Item noStyle name={[index, 'nums']} rules={[{ required: true }]}>
                    <InputNumber style={{ width: 60 }} min={1} precision={0} />
                  </Form.Item>
                )
              },
            },
            {
              title: (
                <Button
                  size="small"
                  type="primary"
                  ghost
                  icon={<PlusOutlined />}
                  disabled={disabledBtn}
                  onClick={() => {
                    LocalProductPickModal.open({
                      productType: [1],
                      disablePickSkuIds: allValues.singleProductInfos.map(d => d.skuId),
                      onPick: async ({ localProduct }) => {
                        const { skuId, sku, spu, imgUrl, productEnTitle } = localProduct
                        add({ skuId, sku, spu, imgUrl, productEnTitle, nums: 1 })
                        const lastIndex = allValues.singleProductInfos.length
                        setTimeout(() => {
                          const rowId = `${rowIdPrefix}${lastIndex}`
                          document.getElementById(rowId)?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
                        }, 100)
                      },
                    })
                  }}
                >
                  {t('2-common.tian-jia')}
                </Button>
              ),
              width: '25%',
              align: 'center',
              render: (_v, _r, index) => (
                <Popconfirm title={t('2-common.que-ren-yao-shan-chu-ma')} onConfirm={() => remove(index)}>
                  <Button type="link" icon={<DeleteOutlined />} danger disabled={disabledBtn || fields.length <= 2} />
                </Popconfirm>
              ),
            },
          ]}
        />
      )}
    </Form.List>
  )
}
